import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IBlock, IBlocks } from '../../../../store/reducers/editors';
import useStyles from '../../../../views/EditorItem/styles';
import cx from 'clsx';
import { EditableBlock } from '../EditableBlock';
import { useEditorSitemap } from '../../../../hooks/useEditorSitemap';
import { useDND } from '../../../../hooks/useDND';
import { useTypeSelector } from '../../../../hooks/useTypesSelector';
import { SnackbarType, useSnackbar } from '../../../../hooks/useSnackbar';
import { CommentsWrapper } from '../../../Comments/CommentsWrapper';
import { BlockTitleInput } from './BlockTitleInput';
import { RemoveBlock } from './RemoveBlock';
import { AddBtn } from './AddBtn';
import { useSettingsEditor } from '../../../../hooks/useSettingEditor';

export const Block = (props: IBlock) => {
  const cc = useStyles();
  const {
    putDraftServer,
    setBlockComment,
    updBlockComment,
    deleteBlockComment,
    dndBlock,
    changeTitleFromBlockById,
    removeBlockByPageIdAndIdx,
  } = useEditorSitemap();
  const { setSnackbarValue } = useSnackbar();
  const [showSetCommnet, setShowSetComment] = useState(false);
  const {
    setDragBlock,
    setDragOverBlock,
    setPageBlockDragged,
    setCursor,
    setNoScroll,
  } = useDND();
  const {
    setShowEditor,
    setBlockIdx,
    setSelectedPageId,
    setEditorTitle,
    setEditBlock,
  } = useSettingsEditor();
  const dragBlock = useTypeSelector((s) => s.settings.dnd.dragBlock);
  const dragOverBlock = useTypeSelector((s) => s.settings.dnd.dragOverBlock);
  const editor = useTypeSelector((s) => s.editor);
  const profile = useTypeSelector((s) => s.profile.profile);
  const reg = useMemo(() => /\sblock\s/g, []);
  const [over, setOver] = useState<string>('');

  // @ts-ignore
  const color = props.color && cc[props.color];
  const Active =
    // @ts-ignore
    props.color && props.active ? cc[`active_${props.color}`] : props.color;

  const toggleSetComments = useCallback(() => {
    setShowSetComment(!showSetCommnet);
  }, [showSetCommnet]);

  const setCloseEditor = useCallback(() => {
    props.onMouseOut && props.onMouseOut();
    props.setShowEditableBlock(false);
  }, [props]);

  const addBlockComment = useCallback(
    (val: string) => {
      if (!props.pub) {
        setBlockComment(props.id, val);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [props.id, setBlockComment, setSnackbarValue, props.pub],
  );

  const updateBlockComment = useCallback(
    (val: string, idx: number) => {
      if (!props.pub) {
        updBlockComment(props.id, val, idx);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [props.id, updBlockComment, setSnackbarValue, props.pub],
  );

  const removeBlockComment = useCallback(
    (page: number, val: number) => {
      if (!props.pub) {
        deleteBlockComment(props.id, val);
        return;
      }

      setSnackbarValue('Нет прав на изменения комментария', SnackbarType.Error);
    },
    [props.id, deleteBlockComment, setSnackbarValue, props.pub],
  );

  const onCloseComment = useCallback(async () => {
    if (!props.pub) {
      await putDraftServer(editor.name, editor.pages);
    }
    toggleSetComments();
  }, [props.pub, editor, toggleSetComments, putDraftServer]);

  const dragOver = useCallback(
    (e: BaseSyntheticEvent, block: IBlock) => {
      e.preventDefault();

      if (e.target.innerHTML !== over) {
        const find =
          //@ts-ignore
          e.nativeEvent.path &&
          //@ts-ignore
          e.nativeEvent.path.find((items: any) =>
            reg.test(items.className) ? true : false,
          );
        find && find.classList.add(cc.blockDND);
        if (dragOverBlock?.id !== block.id) {
          setDragOverBlock(block, block.pageId);
          setOver(e.target.innerHTML);
        }
      }
    },
    [reg, setDragOverBlock, cc.blockDND, over, dragOverBlock],
  );

  const dragLeave = useCallback(
    (e: BaseSyntheticEvent) => {
      const find =
        //@ts-ignore
        e.nativeEvent.path &&
        //@ts-ignore
        e.nativeEvent.path.find((items: any) =>
          reg.test(items.className) ? true : false,
        );
      find && find.classList && find.classList.remove(cc.blockDND);
      e.target.classList.remove(cc.blockDND);
    },
    [cc.blockDND, reg],
  );

  const dragStart = useCallback(
    (e: BaseSyntheticEvent, block: IBlock) => {
      setPageBlockDragged(true);
      props.onMouseOut && props.onMouseOut();
      setDragBlock(block, block.pageId);
    },
    [props, setDragBlock, setPageBlockDragged],
  );

  const dragEnd = (e: any) => {
    setTimeout(() => {
      setPageBlockDragged(false);
      setCursor('grab');
      setDragOverBlock(null, null);
      props.onMouseOver && props.onMouseOver();
    }, 1000);
  };

  const drop = useCallback(
    (e: BaseSyntheticEvent, block: IBlock) => {
      e.preventDefault();
      // e.target.style.boxShadow = 'none';
      e.target.classList.remove(cc.blockDND);
      const find =
        //@ts-ignore
        e.nativeEvent.path &&
        //@ts-ignore
        e.nativeEvent.path.find((items: any) =>
          reg.test(items.className) ? true : false,
        );
      find && find.classList && find.classList.remove(cc.blockDND);
      props.onMouseOver && props.onMouseOver();
      dragBlock && dragOverBlock && dndBlock(dragBlock, dragOverBlock);
      setDragOverBlock(null, null); // reset
      setTimeout(() => {
        setPageBlockDragged(false);
        setCursor('grab');
      }, 1000);
    },
    [
      reg,
      props,
      dndBlock,
      setCursor,
      dragBlock,
      cc.blockDND,
      dragOverBlock,
      setPageBlockDragged,
      setDragOverBlock,
    ],
  );

  const changeTitleBlock = useCallback(
    async (page: IBlocks, newVal: string) => {
      if (!props.pub) {
        if (profile) {
          await changeTitleFromBlockById(page.id, newVal);
        }
        return;
      }

      setSnackbarValue('Нет прав на редактирование блока', SnackbarType.Error);
    },
    [changeTitleFromBlockById, profile, props.pub, setSnackbarValue],
  );
  const addAfterBlock = useCallback(
    (id: string, idx: number) => {
      if (!props.pub) {
        if (profile) {
          setEditBlock(null);
          setSelectedPageId(id);
          setBlockIdx(idx);
          setShowEditor();
          setEditorTitle('Добавить блок');
          setNoScroll(true);
        }
        return;
      }

      setSnackbarValue(
        'Нет прав на редактирование страницы',
        SnackbarType.Error,
      );
    },
    [
      setEditBlock,
      setSelectedPageId,
      setBlockIdx,
      setShowEditor,
      setEditorTitle,
      profile,
      props.pub,
      setSnackbarValue,
      setNoScroll,
    ],
  );

  const addBlockHendler = useCallback(
    (pageId: string, blockId: string, index: number) => {
      if (!props.pub) {
        addAfterBlock(pageId, index);
        props.setShowEditPageId(blockId);
        props.setShowEditableBlock(true);
        return;
      }

      setSnackbarValue(
        'Нет прав на редактирование страницы',
        SnackbarType.Error,
      );
    },
    [props, setSnackbarValue, addAfterBlock],
  );

  const removeBlock = useCallback(
    (id: string, block: IBlocks) => {
      if (!props.pub) {
        profile && removeBlockByPageIdAndIdx(id, block);
        return;
      }

      setSnackbarValue('Нет прав на удаление блока', SnackbarType.Error);
    },
    [profile, removeBlockByPageIdAndIdx, props.pub, setSnackbarValue],
  );

  useEffect(() => {
    if (props.pub) {
      props.onMouseOut && props.onMouseOut();
    }
  }, [props]);

  console.log('props', props, 'dragOverBlock', dragOverBlock);

  return (
    <>
      {!props.titleEditAllowed ? (
        <div
          className={cx(cc.block, color, Active, 'block', cc.opacity1)}
          draggable={true}
          // onDrag={(e) => e.preventDefault()}
          onDragOver={(e) => !props.pub && dragOver(e, props)}
          onDragLeave={(e) => !props.pub && dragLeave(e)}
          onDragStart={(e) => !props.pub && dragStart(e, props)}
          onDragEnd={(e) => !props.pub && dragEnd(e)}
          onDrop={(e) => !props.pub && drop(e, props)}
          onMouseOver={() =>
            props.pub === false && props.onMouseOver && props.onMouseOver()
          }
          onMouseOut={() =>
            props.pub === false && props.onMouseOut && props.onMouseOut()
          }
        >
          <CommentsWrapper
            className="block_comments_btn"
            comments={props.comments}
            page={props}
            id={props.id}
            pub={props.pub}
            onClose={onCloseComment}
            onRemove={removeBlockComment}
            onUpdate={(block, comment, idx) => updateBlockComment(comment, idx)}
            onAdded={(block, val) => addBlockComment(val)}
            title="Описание блока"
          />
          <RemoveBlock
            className="block_remove_btn"
            onClick={() => removeBlock(props.pageId, props)}
            style={{ top: 5 }}
          />
          <AddBtn
            onClick={() => addBlockHendler(props.pageId, props.id, props.index)}
            className={cx('block_add_btn', cc.blockHeaderBtn)}
            title="Добавить блок ниже"
            style={{ bottom: -28 }}
          />
          <div className={cc.blockHeader}>
            {!props.titleEditAllowed ? (
              <BlockTitleInput
                page={props}
                className={cc.blockTitle}
                value={props.title}
                onChange={changeTitleBlock}
              />
            ) : (
              <span
                className={cc.onHover}
                onClick={() => props.clickOnBlock(props.id, props)}
              >
                {props.title}
              </span>
            )}
          </div>
          <div
            className={cx(cc.blockBody, props.className, cc.onHover)}
            onClick={() => props.clickOnBlock(props.id, props)}
          >
            {props.children}
          </div>
          {props.showEditableBlock && props.editBlockId === props.id && (
            <EditableBlock setCloseEditor={setCloseEditor} />
          )}

          {dragOverBlock?.id === props.id ? (
            <div
              style={{
                width: 'calc(100% + 10px)',
                transform: 'translateX(-10px) translateY(5px)',
                height: 2,
                background: '#3090E8',
                display: 'block',
                position: 'absolute',
                zIndex: 999999,
              }}
            >
              <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: 'absolute', left: -2, top: -4, zIndex: 999 }}
              >
                <path
                  d="M6.50385 6.66605C7.69131 5.87441 7.69131 4.1295 6.50385 3.33785L3.1094 1.07489C1.78029 0.188814 0 1.1416 0 2.73899V7.26492C0 8.86231 1.78029 9.81509 3.1094 8.92902L6.50385 6.66605Z"
                  fill="#3090E8"
                />
              </svg>
              <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  position: 'absolute',
                  right: -3,
                  top: -4,
                  zIndex: 999,
                }}
              >
                <path
                  d="M1.49615 6.66605C0.308686 5.87441 0.308685 4.1295 1.49615 3.33785L4.8906 1.07489C6.21971 0.188814 8 1.1416 8 2.73899V7.26492C8 8.86231 6.21971 9.81509 4.8906 8.92902L1.49615 6.66605Z"
                  fill="#3090E8"
                />
              </svg>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div
          className={cx(cc.block, color, Active, cc.onHover)}
          onClick={() => props.clickOnBlock(props.id, props)}
          onMouseOver={() => props.onMouseOver && props.onMouseOver()}
          onMouseLeave={() => props.onMouseOut && props.onMouseOut()}
        >
          <div className={cc.blockHeader}>
            <span className={cc.blockHeader_title}>{props.title}</span>
          </div>
          <div className={cx(cc.blockBody, props.className)}>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};
