import React from 'react';
import useStyles from './styles';

export const Back = () => {
  const classes = useStyles();
  const url = process.env.REACT_APP_FACE_URL || '//structura.app';
  console.log('url', url);
  return (
    <a href={url} className={classes.backWrapper}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 4.92969L2.92969 12L10 19.0703L11.5 17.5703L6.92969 13H21V11H6.92969L11.5 6.42969L10 4.92969Z"
          fill="#264A67"
        />
      </svg>
      <h2>На сайт</h2>
    </a>
  );
};
