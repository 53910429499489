import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRoute } from 'wouter';
import { Editor } from './editor';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { useDND } from '../../hooks/useDND';
import { Zoom } from '../../components/Editor/item/Zoom/Zoom';
import { IconsOutline } from '../../components/IconOutliner';
import { useEditorList } from '../../hooks/useEditorList';
import { useEditorSitemap } from '../../hooks/useEditorSitemap';
import { ProjectDescription } from '../../components/Editor/item/ProjectDescription';
import { useEffectOnce } from 'react-use';

export const EditorItem = () => {
  const [, params] = useRoute('/editor/:id');
  const profile = useTypeSelector((s) => s.profile.profile);
  const pageBlockDragged = useTypeSelector(
    (s) => s.settings.dnd.pageBlockDragged,
  );
  const cursor = useTypeSelector((s) => s.settings.dnd.cursorDnD);
  const ele = useRef(null);
  const drag_content = useRef(null);

  const editorId = useTypeSelector((s) => s.editor.id);
  const [loading, setLoading] = useState(false);
  const { setDraftId } = useEditorList();
  const { getDraft, setEvents } = useEditorSitemap();
  const lastPosition = useRef({ x: 0, y: 0 });
  const positionRef = useRef({ x: 0, y: 0 }); // Хранение позиции
  const { setCursor, rollDown, rollUp, setScale } = useDND();

  const pub = useMemo(() => (profile === null ? true : false), [profile]);

  const goHome = useCallback(() => {
    lastPosition.current = { x: 0, y: 0 };
    positionRef.current = { x: 0, y: 0 };
    //@ts-ignore
    drag_content.current!.style.transform = `translate(${0}px, ${0}px)`;
    setScale(1);
  }, [drag_content, setScale]);

  const mouseDownHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      // console.log('e down', e);
      // e.preventDefault()
      if (!pageBlockDragged) {
        setCursor('grabbing');
        const clientX =
          e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
        const clientY =
          e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;
        lastPosition.current = { x: clientX, y: clientY }; // Запоминаем начальные координаты
        //@ts-ignore
        drag_content.current!.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
      }
    },
    [pageBlockDragged, setCursor],
  );

  const mouseMoveHandler = useCallback(
    (e: MouseEvent | TouchEvent) => {
      // console.log('e move', e);
      // e.preventDefault()

      if (
        cursor === 'grabbing' &&
        ele !== null &&
        ele.current &&
        !pageBlockDragged
      ) {
        const clientX =
          e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
        const clientY =
          e instanceof MouseEvent ? e.clientY : e.touches[0].clientY;

        const deltaX = clientX - lastPosition.current.x;
        const deltaY = clientY - lastPosition.current.y;

        positionRef.current = {
          x: positionRef.current.x + deltaX,
          y: positionRef.current.y + deltaY,
        };

        lastPosition.current = { x: clientX, y: clientY };
        //@ts-ignore
        drag_content.current!.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`;
      }
    },
    [cursor, drag_content, pageBlockDragged],
  );

  const mouseUpHandler = useCallback(
    (e: any) => {
      // e.preventDefault()
      if (!pageBlockDragged) {
        setCursor('grab');
      }
    },
    [pageBlockDragged, setCursor],
  );

  const checkDrafts = useCallback(async () => {
    setLoading(true);
    try {
      if (params && params.id) {
        await getDraft(params.id);
        await setEvents(params.id, 'OPEN');
        setDraftId(params.id);
      }
    } finally {
      setLoading(false);
    }
  }, [getDraft, setEvents, setDraftId, params]);

  const init = useCallback(
    async (ev: any) => {
      // ev.preventDefault();
      // return (ev.returnValue = 'Are you sure you want to close?');
      params?.id && (await setEvents(params.id, 'OPEN'));
    },
    [params?.id, setEvents],
  );

  useEffectOnce(() => {
    checkDrafts();
    window.addEventListener('beforeunload', init);

    return () => {
      window.removeEventListener('beforeunload', init);
    };
  });

  useEffect(() => {
    if (ele.current !== null) {
      //@ts-ignoreele
      ele.current.addEventListener('mousemove', mouseMoveHandler);
      //@ts-ignore
      ele.current.addEventListener('touchmove', mouseMoveHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mouseup', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchend', mouseUpHandler);
      //@ts-ignoreele
      ele.current.addEventListener('mousedown', mouseDownHandler);
      //@ts-ignoreele
      ele.current.addEventListener('touchstart', mouseDownHandler);
    }

    return () => {
      if (ele.current !== null) {
        //@ts-ignoreele
        ele.current.removeEventListener('mousemove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchmove', mouseMoveHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mouseup', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchend', mouseUpHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('mousedown', mouseDownHandler);
        //@ts-ignoreele
        ele.current.removeEventListener('touchstart', mouseDownHandler);
      }
    };
  }, [ele, mouseDownHandler, mouseUpHandler, mouseMoveHandler]);

  return (
    <>
      {!loading ? (
        <>
          <ProjectDescription />
          <Zoom
            in={() => rollUp(0.1)}
            out={() => rollDown(0.1)}
            goHome={goHome}
          />
        </>
      ) : (
        <></>
      )}

      <div
        id="drag_wrapper"
        className="drag_wrapper"
        style={{ cursor }}
        ref={ele}
      >
        <div className="drag_content" id="drag_content" ref={drag_content}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                height: 'calc(100vh - 90px)',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
              }}
            >
              <IconsOutline
                types="loading"
                defaultColor="#3090E8"
                width={66}
                height={66}
              />
            </div>
          ) : (
            <>
              {params && params.id && editorId && (
                <Editor id={params.id} pub={pub} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
