import React, { memo, useCallback, useEffect, useState } from 'react';
import useStyles from '../../../../views/EditorItem/styles';
import { AddBtn } from './AddBtn';
import cx from 'clsx';
import { IBlocks, IComment, IPages } from '../../../../store/reducers/editors';
import { RemoveBlock } from './RemoveBlock';
import { useTypeSelector } from '../../../../hooks/useTypesSelector';
import { BlockTitleInput } from './BlockTitleInput';
import { SnackbarType, useSnackbar } from '../../../../hooks/useSnackbar';
import { CommentsWrapper } from '../../../Comments/CommentsWrapper';
import { useInView } from 'react-intersection-observer';
import { GenerateBlocks } from '../GenerateBlock';
import { useSettingsEditor } from '../../../../hooks/useSettingEditor';
import { blocksList } from '../../../../store/reducers/settings/editableBlockReducer';
import { GeneratePages } from '../GeneratePage';
import { IconsOutline } from '../../../IconOutliner';

interface IPageProps {
  id: string;
  idx: number;
  title: string;
  comments: IComment[];
  page: IPages;
  // blocks?: React.ReactNode;
  // children?: React.ReactNode;
  className?: string;
  pub?: boolean;
  savePageAfterRender: boolean;
  setPageTitle: (page: IPages, newVal: string) => void;
  removePage: (page: IPages) => void;
  addPage: (page: IPages) => void;
  addCommentPage: (page: IPages, comment: string) => void;
  removePageComment: (page: IPages, idx: number) => void;
  updateCommentPage: (page: IPages, val: string, idx: number) => void;
  updateDraft: () => void;
}

export const PageBlock = memo((props: IPageProps) => {
  const cc = useStyles();
  const pagesCount = useTypeSelector((s) => s.editor.pages_count);
  const editorPreview = useTypeSelector((s) => s.editor.editor_preview);
  const { ref, inView } = useInView({
    delay: 500,
    rootMargin: '300px',
  });
  const {
    setSelectedPageId,
    setEditorTitle,
    showRemoveBtn,
    setEditBlock,
    setSettingEditorList,
    setBlockIdx,
  } = useSettingsEditor();
  const { setSnackbarValue } = useSnackbar();
  const profile = useTypeSelector((s) => s.profile.profile);
  const [firstRender, setFirstRender] = useState(true);
  const [show, setShow] = useState(false);
  const [showEditPageId, setShowEditPageId] = useState('');

  // const setCloseEditor = useCallback(() => {
  //   if (profile) {
  //     hideRemoveBtn();
  //     setShowEditPageId('');
  //     setShow(false);
  //   }
  // }, [profile, hideRemoveBtn]);

  const clickOnBlock = useCallback(
    (id: string, block: IBlocks) => {
      if (props.pub) {
        setSnackbarValue(
          'Нет прав на редактирование блока',
          SnackbarType.Error,
        );
        return;
      }

      if (profile) {
        setShow(false);
        block && setSettingEditorList(blocksList);
        block && setEditBlock(block);
        setSelectedPageId(id);
        setEditorTitle('Изменить блок');
        showRemoveBtn();
        setShowEditPageId(block.id);
        setShow(true);
        setBlockIdx(null);
      }
    },
    [
      profile,
      setBlockIdx,
      setEditBlock,
      setEditorTitle,
      setSelectedPageId,
      showRemoveBtn,
      props.pub,
      setSnackbarValue,
      setSettingEditorList,
    ],
  );

  useEffect(() => {
    inView && firstRender && setFirstRender(false);
  }, [inView, firstRender]);

  //@ts-ignore
  // console.log('window.performance.memory', window.performance.memory);

  return (
    <>
      {pagesCount && pagesCount >= 100 ? (
        <div
          className={cx(cc.pageWrapper, props.className)}
          id={`PAGE_${props.id}`}
          ref={ref}
        >
          <div className={cc.page}>
            {props.savePageAfterRender && (!firstRender || inView || editorPreview) ? (
              <>
                <CommentsWrapper
                  page={props.page}
                  className="page_comments_btn"
                  comments={props.comments}
                  pub={props.pub}
                  id={props.id}
                  onUpdate={props.updateCommentPage}
                  onRemove={props.removePageComment}
                  onAdded={props.addCommentPage}
                  onClose={props.updateDraft}
                  title="Описание страницы"
                />
                <AddBtn
                  onClick={() => props.addPage(props.page)} //props.addPage(props.id, props.idx)}
                  className={cx('page_add_btn', cc.addBtn)}
                  title="Добавить страницу"
                />
                <RemoveBlock
                  className="page_remove_btn"
                  onClick={() => props.removePage(props.page)}
                  style={{ top: 5 }}
                />
              </>
            ) : (
              <></>
            )}

            <svg
              width="27"
              height="7"
              viewBox="0 0 27 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={cc.pageBtn}
            >
              <path
                d="M2.5 3.5C3 1 3.5 0 5 0H20.5H22C23.5 0 24 1 24.5 3.5C25 6 25.5 7 27 7H0C1.5 7 2 6 2.5 3.5Z"
                fill="#C7D6E6"
              />
            </svg>
            <div>
              <div className={cc.pageHeader}>
                {props.savePageAfterRender && (!firstRender || inView || editorPreview) ? (
                  <BlockTitleInput
                    page={props.page}
                    className={cc.pageTitle}
                    value={props.title}
                    onChange={props.setPageTitle}
                    // onBlur={updateDraft}
                  />
                ) : (
                  // <IconsOutline types="loading" height={30} />
                  <></>
                )}
              </div>

              <div className={cc.pageBody}>
                {props.savePageAfterRender && (!firstRender || inView || editorPreview) ? (
                  <>
                    {props.page.blocks && (
                      <GenerateBlocks
                        blocks={props.page.blocks}
                        clickOnBlock={clickOnBlock}
                        pageId={props.page.id}
                        showAddBtn={true}
                        showEditableBlock={show}
                        setShowEditableBlock={setShow}
                        editBlockId={showEditPageId}
                        setShowEditPageId={setShowEditPageId}
                        titleEditAllowed={false}
                        pub={props.pub}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <IconsOutline types="loading" height={30} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={cc.childs}>
            {[...(props.page.subpages || [])].length ? (
              <div className={cc.childWrapper}>
                <GeneratePages
                  editor={props.page.subpages || []}
                  key={props.idx}
                  pub={props.pub}
                  savePageAfterRender={props.savePageAfterRender}
                  setPageTitle={props.setPageTitle}
                  removePage={props.removePage}
                  addPage={props.addPage}
                  addCommentPage={props.addCommentPage}
                  updateDraft={props.updateDraft}
                  removePageComment={props.removePageComment}
                  updateCommentPage={props.updateCommentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div
          className={cx(cc.pageWrapper, props.className)}
          id={`PAGE_${props.id}`}
          ref={ref}
        >
          <div className={cc.page}>
            <CommentsWrapper
              page={props.page}
              className="page_comments_btn"
              comments={props.comments}
              pub={props.pub}
              id={props.id}
              onUpdate={props.updateCommentPage}
              onRemove={props.removePageComment}
              onAdded={props.addCommentPage}
              onClose={props.updateDraft}
              title="Описание страницы"
            />
            <AddBtn
              onClick={() => props.addPage(props.page)} //props.addPage(props.id, props.idx)}
              className={cx('page_add_btn', cc.addBtn)}
              title="Добавить страницу"
            />
            <RemoveBlock
              className="page_remove_btn"
              onClick={() => props.removePage(props.page)}
              style={{ top: 5 }}
            />

            <svg
              width="27"
              height="7"
              viewBox="0 0 27 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={cc.pageBtn}
            >
              <path
                d="M2.5 3.5C3 1 3.5 0 5 0H20.5H22C23.5 0 24 1 24.5 3.5C25 6 25.5 7 27 7H0C1.5 7 2 6 2.5 3.5Z"
                fill="#C7D6E6"
              />
            </svg>
            <div>
              <div className={cc.pageHeader}>
                <BlockTitleInput
                  page={props.page}
                  className={cc.pageTitle}
                  value={props.title}
                  onChange={props.setPageTitle}
                  // onBlur={updateDraft}
                />
              </div>

              <div className={cc.pageBody}>
                {props.page.blocks && (
                  <GenerateBlocks
                    blocks={props.page.blocks}
                    clickOnBlock={clickOnBlock}
                    pageId={props.page.id}
                    showAddBtn={true}
                    showEditableBlock={show}
                    setShowEditableBlock={setShow}
                    editBlockId={showEditPageId}
                    setShowEditPageId={setShowEditPageId}
                    titleEditAllowed={false}
                    pub={props.pub}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={cc.childs}>
            {[...(props.page.subpages || [])].length ? (
              <div className={cc.childWrapper}>
                <GeneratePages
                  editor={props.page.subpages || []}
                  key={props.idx}
                  pub={props.pub}
                  savePageAfterRender={props.savePageAfterRender}
                  setPageTitle={props.setPageTitle}
                  removePage={props.removePage}
                  addPage={props.addPage}
                  addCommentPage={props.addCommentPage}
                  updateDraft={props.updateDraft}
                  removePageComment={props.removePageComment}
                  updateCommentPage={props.updateCommentPage}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
});
